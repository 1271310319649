import React, { useEffect, useState } from "react";
import SidebarAnt from '../components/SidebarAnt';
import { GetAllData, GetLiveChannels } from "../functions/apis";
import Android from '../assets/icons/cards/android.svg'
import Appstore from '../assets/icons/cards/apple.svg'
import AppFactoryIcon from '../assets/icons/sidebar/AppFactory-icon.svg'

import Appstore_White from '../assets/icons/cards/appstore.svg'
import Playstore from '../assets/icons/cards/googleplay.svg'
import Huawei from '../assets/icons/cards/HuaweiIcon.png'
import AppGallery from '../assets/icons/cards/AppGallery.png'
import "../styles/Applications.css"
import Header from '../components/Header';

export default function Applications() {

    const [apps, setApps] = useState([]);

    useEffect(() => {
        const checkForData = () => {
            const allData = JSON.parse(sessionStorage.getItem("AllData"));
            if (allData) {
                clearInterval(interval); // Stop checking once data is found

                const urlParams = new URLSearchParams(window.location.search);
                const platform = urlParams.get("platform");

                if (platform === "android" && allData.AndroidApps) {
                    setApps(allData.AndroidApps.googleplay || []);
                    console.log("Android");
                } else if (platform === "ios" && allData.IOSApps) {
                    setApps(allData.IOSApps || []);
                    console.log("iOS");
                } else if (platform === "huawei" && allData.AndroidApps) {
                    setApps(allData.AndroidApps.huawei || []);
                    console.log("Huawei");
                } else {
                    console.log("Mixed");
                    const mixedApps = getRandomMixedApps(
                        allData.AndroidApps?.googleplay || [],
                        allData.IOSApps || [],
                        allData.AndroidApps?.huawei || [],
                        20
                    ); // Number of apps to show
                    setApps(mixedApps);
                }
            } else {
                console.log("Waiting for sessionStorage data...");
            }
        };

        const interval = setInterval(checkForData, 500); // Check every 500ms

        return () => clearInterval(interval); // Clean up on unmount
    }, [window.location.search]); // Add dependency here


    function ViewAppDetail_Second(app) {
        const stringJson = JSON.stringify(app);
        const encodedData = btoa(stringJson);
        const urlSafeEncodedData = encodeURIComponent(encodedData); // URL encode the Base64 string
        window.location.href = `/AppDetails_Second?data=${urlSafeEncodedData}`; // Adding `data=` to ensure it's a query parameter
    }


    const getRandomMixedApps = (androidApps, iosApps,huwaei, count) => {
        const combinedApps = [...androidApps, ...iosApps, ...huwaei];
        const shuffled = combinedApps.sort(() => 0.5 - Math.random()); // Shuffle array
        return shuffled.slice(0, count); // Return 'count' number of random apps
    };



    return (
        <section className='d-flex ' style={{ height: '100vh' }}>
            <SidebarAnt />
            <div className='main-content' >
                <Header />
                <div className='Dashboard_Body'>

                    {apps?apps.map((app, index) => {
                        return (
                            <div className="App_Card" data-index={app.AppName} >
                                {
                                    app.Platform === "IOS" ? (
                                        <img className="Platform_Icon" src={Appstore} />
                                    ) : app.StoreLink && app.StoreLink.includes("appgallery.huawei.com") ? (
                                        <img className="Platform_Icon" src={Huawei} />
                                    ) : (
                                        <img className="Platform_Icon" src={Android} />
                                    )
                                }



                                <img className="App_Icon" src={app.Logo ? app.Logo : AppFactoryIcon} />

                                <p className="App_Name" >{app.AppName}</p>

                                <button
                                    className="os_button_2 d-flex"
                                    style={{
                                        background:
                                            app.Platform === "IOS"
                                                ? '#757575bf'
                                                : app.StoreLink && app.StoreLink.includes("appgallery.huawei.com")
                                                    ? '#3c3c3c' // Example color for Huawei
                                                    : '#26C46D',
                                    }}
                                    onClick={() =>ViewAppDetail_Second(app)}
                                >
                                    <img style={{ height: '100%' }}
                                        src={
                                            app.Platform === "IOS"
                                                ? Appstore_White
                                                : app.StoreLink && app.StoreLink.includes("appgallery.huawei.com")
                                                    ? AppGallery // Add your Huawei AppGallery icon
                                                    : Playstore
                                        }
                                    />
                                    <div className="d-flex flex-column align-items-start">
                                        <span style={{ fontSize: '10px', color: 'white' }}>Available on</span>
                                        <span
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                fontWeight: '700',
                                            }}
                                        >
                                            {app.Platform === "IOS"
                                                ? "App Store"
                                                : app.StoreLink && app.StoreLink.includes("appgallery.huawei.com")
                                                    ? "App Gallery"
                                                    : "Play Store"}
                                        </span>
                                    </div>
                                </button>



                            </div>
                        )
                    }):""}

                </div>

            </div>

        </section>
    )
}