import React, { useState } from "react";
import Lottie from "lottie-react";
import BannerBackground from '../assets/icons/homepage/Hovering-Green.json'
import Logo from '../assets/icons/sidebar/appsfactory-fulllogo.svg'
import { Input, Button } from 'antd';
import '../styles/Contactus.css'
import axios from "axios";

export default function ContactUs() {

    const lottieStyles = [
        { id: "lottie", style: { transform: "rotate(30deg)", width: "12%", height: "12%", top: "2%", left: "30%" } },
        { id: "lottie1", style: { transform: "rotate(45deg)", width: "12%", height: "12%", top: "14%", left: "60%" } },
        { id: "lottie2", style: { transform: "rotate(60deg)", width: "12%", height: "12%", bottom: "15%", left: "70%" } },
        { id: "lottie3", style: { transform: "rotate(90deg)", width: "12%", height: "12%", bottom: "20%", right: "70%" } },
        { id: "lottie4", style: { width: "6%", height: "6%", top: "10%", left: "90%" } },
        { id: "lottie5", style: { width: "7%", height: "7%", top: "20%", left: "10%" } },
    ];

    const [formData, setFormData] = useState({
        Name: '',
        LastName: '',
        Email: '',
        Message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
    
        try {
            const response = await fetch(
                'https://s520ki4x5h.execute-api.eu-central-1.amazonaws.com/default/appsfactory-contactus',
                {
                    method: 'POST',
                    mode: 'no-cors', // Allows sending the request without enforcing CORS checks
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData), // Convert form data to JSON string
                }
            );

            console.log(response)
            // Since `no-cors` mode prevents reading the response, you cannot verify the success in this mode.
            alert('Email Sent');
        } catch (error) {
            console.error('Failed to send email:', error);
            alert('Error sending email. Please try again.');
        }
    };
    



    return (
        <section className="banner d-flex flex-column justify-content-between">
            <div className="lottie-container">
                {lottieStyles.map((lottie) => (
                    <div key={lottie.id} id={lottie.id} style={lottie.style}>
                        <Lottie animationData={BannerBackground} loop={true} />
                    </div>
                ))}
            </div>

            <div className="container d-flex flex-column "
                style={{ height: '80%', backdropFilter: 'blur(5px)' }} >

                <div className="mt-4 d-flex flex-direction-row justify-content-between align-items-center" >
                    <img
                        className="MainLogo"
                        src={Logo} />

                    <a href="/" style={{color:'white'}} >Back</a>
                </div>



                <div className="row justify-content-center align-items-center"
                    style={{ height: '100%', marginTop: '5rem' }}>

                    <h1 className="MainTitle">
                        Contact Us</h1>

                    <form className="contact-us-form" 
                    style={{maxWidth:'700px'}}  onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name">Name</label>
                            <Input
                                id="name"
                                name="Name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="lastName">Last Name</label>
                            <Input
                                id="lastName"
                                name="LastName"
                                placeholder="Last Name"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="email">Email</label>
                            <Input
                                id="email"
                                name="Email"
                                type="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="message">Message</label>
                            <Input.TextArea
                                id="message"
                                name="Message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                            />
                        </div>
                        
                        <Button 
                        style={{height:'45px' , width:'195px' , margin:'auto'}}
                        className="primary-button" type="primary" htmlType="submit">Submit</Button>
                    </form>



                </div>


            </div>
        </section>
    )
}