import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Menu, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import Logo from '../assets/icons/sidebar/AppFactory-icon.svg';
import Logo2 from '../assets/icons/sidebar/AppsFactory-txt.svg';
import ApplicationOutline from '../assets/icons/sidebar/Applications-filled.svg';
import DiceFilled from '../assets/icons/sidebar/Dice-filled.svg';
import MoviesFilled from '../assets/icons/sidebar/Movies-filled.svg';
import LiveFilled from '../assets/icons/sidebar/Streaming-filled.svg'
import LogoutOutline from '../assets/icons/sidebar/Logout.svg';
import Downloadable from '../assets/icons/sidebar/Downloadable-outline.svg';
import UserOutline from '../assets/icons/sidebar/account.svg';
import SubscribeOutline from '../assets/icons/sidebar/subscription.svg';
import Loader from "../components/Loader";

import '../styles/Sidebar.css'

import { GetAllData, Logout } from "../functions/apis";
import { hover } from '@testing-library/user-event/dist/hover';




const SidebarAnt = () => {

    const checkScreenSize = () => {
        if (window.matchMedia("(max-width: 700px)").matches) {
            setCollapsed(true);
        }
    };



    const [collapsed, setCollapsed] = useState(false);
    const [OpenAccount, SetOpenAccount] = useState(false);
    const [OpenSubscription, SetOpenSubscribtion] = useState(false);
    const [Login_data, setLogin_Data] = useState([]);


    const showAccount = () => {
        SetOpenAccount(true);
    };
    const showSubscribtion = () => {
        SetOpenSubscribtion(true);
    };
    const CloseAccount = () => {
        SetOpenAccount(false);
    };
    const CloseSubscribtion = () => {
        SetOpenSubscribtion(false);
    };
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };



    const [data, setData] = useState({ AndroidApps: [], IOSApps: [], Games: [], Videos: [], LiveChannels: [] });
    const [AppArray, setAppArray] = useState([])
    const [GamesArray, SetGamesArray] = useState([])
    const [VideosArray, SetVideosArray] = useState([])
    const [LiveChannels, SetLiveChannels] = useState([])
    const [loading, setLoading] = useState(true);
    const [country, setCountry] = useState("");

    const [stateOpenKeys, setStateOpenKeys] = useState(['2', '23']);

    useEffect(() => {
        const fetchData = async () => {
            const allData = await GetAllData(); // Fetch all data at once
            setData(allData);
            setLoading(false);
            setAppArray(allData.AndroidApps.googleplay)
            SetGamesArray(allData.Games)
            SetVideosArray(allData.Videos)
            SetLiveChannels(allData.LiveChannels)
           console.log("LOgin from sidebar")
        };

        checkScreenSize();

        setLogin_Data(JSON.parse(localStorage.getItem("AF_Login_Data")))

        fetchData();
        getCountry()
    }, []);

    const getCountry = async () => {
        try {
            const response = await axios.get("https://dz5yc9ggwa4v9.cloudfront.net/Lambda/GetCountry");
            setCountry(response.data); // Store the country data in state
        } catch (error) {
            console.error("Error fetching country data:", error);
        }
    };

    if (loading) {
        return <Loader />;
    }

    const items = [

        {
            key: '1',
            icon: <img src={ApplicationOutline} alt="Applications" style={{ width: '20px' }} />,
            label: 'Applications',
            children: [
                {
                    key: '45',
                    label: <Link to="/application?platform=ios">IOS</Link>,
                },
                {
                    key: 'android',
                    label: <Link to="/application?platform=android">Android</Link>,
                },
                {
                    key: 'huwaei',
                    label: <Link to="/application?platform=huawei">Huawei</Link>,
                },
            ],
        },
        {
            key: 'sub1',
            label: 'Online Games',
            icon: <img src={DiceFilled} alt="Dice" style={{ width: '20px' }} />,
            children: GamesArray ? GamesArray.map((category, index) => ({
                key: `2-${index}`,  // or you could use category if it's unique
                label:  <Link to={`/Games?Category=${index}`}>
                {category.name.charAt(0).toUpperCase() + category.name.slice(1).toLowerCase()}
              </Link>,
            })) : [],
        },
        {
            key: 'sub2',
            label: 'Videos & Movies',
            icon: <img src={MoviesFilled} alt="Movies" style={{ width: '20px' }} />,
            children: VideosArray ? VideosArray.map((category, index) => ({
                key: `3-${index}`,
                label: <Link to={`/Videos?Category=${index}`}>{category.name}</Link>,
            })) : [],
        },
        {
            key: 'sub3',
            label: <Link to={`/Streaming`}>Live Streaming</Link>,
            icon: <img src={LiveFilled} alt="Live" style={{ width: '20px' }} />,

        },
        country === "AE" ? null : {
            key: 'sub4',
            label: <Link to={`/Downloadable`}>Downloadable</Link>,
            icon: <img src={Downloadable} alt="Downloadable" style={{ width: '20px' }} />
          },
    ];

    const items_bottom = [
        {
            key: 'b-1',
            icon: <img src={UserOutline} alt="Logo" style={{ width: '20px' }} />,
            onClick: showAccount,
            label: "Show My Account",
        },
        {
            key: 'b-2',
            icon: <img src={SubscribeOutline} alt="Logo" style={{ width: '20px' }} />,
            onClick: showSubscribtion,
            label: "Subscribtion Information",
        },
        {
            key: 'logout',
            icon: <img src={LogoutOutline} alt="Logout" style={{ width: '20px' }} />,
            onClick: Logout,
            label: 'Log Out',

        },

    ];



  
    const getLevelKeys = (items1) => {
        const key = {};
        const func = (items2, level = 1) => {
          items2.forEach((item) => {
            if (item.key) {
              key[item.key] = level;
            }
            if (item.children) {
              func(item.children, level + 1);
            }
          });
        };
        func(items1);
        return key;
      };
      const levelKeys = getLevelKeys(items);
      const onOpenChange = (openKeys) => {
        const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
        // open
        if (currentOpenKey !== undefined) {
          const repeatIndex = openKeys
            .filter((key) => key !== currentOpenKey)
            .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
          setStateOpenKeys(
            openKeys
              // remove repeat key
              .filter((_, index) => index !== repeatIndex)
              // remove current level all child
              .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
          );
        } else {
          // close
          setStateOpenKeys(openKeys);
        }
      };
    

    return (
        <div id='sidebarAnt' style={{ height: '100vh', display: 'flex', flexDirection: 'row' }}>

            <div className={`Collapsable_Sidebar ${collapsed ? 'collapesed' : ''}`}>

                <div className="d-flex gap-2 logo-container m-4">
                    <img style={{ width: '30px' }} src={Logo} alt="Logo" />
                    {!collapsed && <img style={{ width: '90px' }} src={Logo2} alt="Logo Text" />}
                </div>


                <Menu
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    openKeys={stateOpenKeys}
                    onOpenChange={onOpenChange}
                    inlineCollapsed={collapsed}
                    style={{
                        height: '65%', overflow: 'auto',
                        background: 'transparent', border: 'none'
                    }}
                    items={items}

                />

                <Menu
                    mode="inline"
                    openKeys={stateOpenKeys}
                    onOpenChange={onOpenChange}
                    inlineCollapsed={collapsed}
                    style={{
                        height: '20%',
                        borderTop: '1px solid #8080803d',
                        background: 'transparent', borderRight: 'none'
                    }}
                    items={items_bottom}

                />
            </div>


            <Button
                type="text"
                onClick={toggleCollapsed}
                className='collapse-button'
                style={{
                    fontSize: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 16,
                }}
            >
                {collapsed ? <span className="bi bi-arrow-right"></span> : <span className="bi bi-arrow-left"></span>}
            </Button>



            <Drawer title="Show My Account" onClose={CloseAccount} open={OpenAccount}>
                <iframe
                    src={`https://apis.mymobibox.mobi/API/InAppWAP/MAD/?bcid=${Login_data.billingChannelId}&MSISDN=${Login_data.username}&langCode=en&cc=false`}
                    width="100%" height="500px" frameBorder="0"
                    title="Account Information"
                />
            </Drawer>

            <Drawer title="Subscribtion Information" onClose={CloseSubscribtion} open={OpenSubscription}>
                <iframe
                    src={`https://apis.mymobibox.mobi/API/InAppWAP/Uns/?bcid=${Login_data.billingChannelId}&MSISDN=${Login_data.username}&langCode=en&cc=false`}
                    width="100%" height="500px" frameBorder="0"
                    title="Account Information"
                />
            </Drawer>

        </div>
    );
};

export default SidebarAnt;
