import React, { useEffect, useState, useRef } from "react";
import Logo from '../assets/icons/sidebar/appsfactory-fulllogo.svg'
import DefaultBackground from '../assets/icons/cards/default-app-bg.jpg'
import AppFactoryIcon from '../assets/icons/sidebar/AppFactory-icon.svg'
import PlayButton from '../assets/icons/cards/playbutton.svg'
import LiveLogo from '../assets/icons/homepage/live.png'
import Lottie from "lottie-react";
import CreditCard from "../assets/icons/homepage/credit-card-svgrepo-com.svg";
import scrollImage from '../assets/icons/homepage/scroll-svg.svg'
import '../styles/Login.css'
import BannerBackground from '../assets/icons/homepage/Hovering-Green.json'
import { GetAllData, GetLiveChannels } from "../functions/apis";
import Loader from "../components/Loader";
import Slider from "react-slick";
import Footer from "../components/Footer";
import { FaCreditCard } from 'react-icons/fa';

export default function Login() {


  const lottieStyles = [
    { id: "lottie", style: { transform: "rotate(30deg)", width: "12%", height: "12%", top: "2%", left: "30%" } },
    { id: "lottie1", style: { transform: "rotate(45deg)", width: "12%", height: "12%", top: "14%", left: "60%" } },
    { id: "lottie2", style: { transform: "rotate(60deg)", width: "12%", height: "12%", bottom: "15%", left: "70%" } },
    { id: "lottie3", style: { transform: "rotate(90deg)", width: "12%", height: "12%", bottom: "20%", right: "70%" } },
    { id: "lottie4", style: { width: "6%", height: "6%", top: "10%", left: "90%" } },
    { id: "lottie5", style: { width: "7%", height: "7%", top: "20%", left: "10%" } },
  ];

  const [data, setData] = useState({ AndroidApps: [], IOSApps: [], Games: [], Videos: [], LiveChannels: [] });
  const [AppArray, setAppArray] = useState([])
  const [ApkArray, SetApkArray] = useState([])
  const [GamesArray, SetGamesArray] = useState([])
  const [VideosArray, SetVideosArray] = useState([])
  const [LiveChannels, SetLiveChannels] = useState([])
  const [loading, setLoading] = useState(true);

  const [activeButton, setActiveButton] = useState('iOS'); // To track the active button
  const [activeGameButton, setActiveGameButton] = useState(0);
  const [activeVideoButton, setActiveVideoButton] = useState(0);

  const [GameIndex, setGameIndex] = useState(0);
  const [VideoIndex, setVideoIndex] = useState(0);


  const sliderRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const next = (sliderIndex) => {
    sliderRefs[sliderIndex].current.slickNext();
  };

  const previous = (sliderIndex) => {
    sliderRefs[sliderIndex].current.slickPrev();
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  useEffect(() => {
    const fetchData = async () => {
      const allData = await GetAllData(); // Fetch all data at once
      setData(allData);
      setLoading(false);
      setAppArray(allData.IOSApps)
      SetGamesArray(allData.Games)
      SetVideosArray(allData.Videos)
      SetLiveChannels(allData.LiveChannels)
    };
    fetchData();


  }, []);


  if (loading) {
    return <Loader />;
  }


  function ShowIOS() {
    setAppArray(data.IOSApps)
    setActiveButton('iOS')
  }

  function ShowAndroid() {
    setAppArray(data.AndroidApps.googleplay)
    setActiveButton('Android')
  }

  function ShowHuawei() {
    setAppArray(data.AndroidApps.huawei)
    setActiveButton('huawei')
  }


  function ViewAppDetail(app) {
    const stringJson = JSON.stringify(app);
    const encodedData = btoa(stringJson);
    const urlSafeEncodedData = encodeURIComponent(encodedData); // URL encode the Base64 string
    window.location.href = `/AppDetails?data=${urlSafeEncodedData}`; // Adding `data=` to ensure it's a query parameter
  }

  function ChangeOnlineGames(index) {
    setActiveGameButton(index);
    setGameIndex(index)
  }

  function ChangeVideo(index) {
    setVideoIndex(index)
    setActiveVideoButton(index); // Set the active button index for Videos
  }


  return (
    <div className="login_body">


      <section className="banner d-flex flex-column justify-content-between">


        <div className="lottie-container">
          {lottieStyles.map((lottie) => (
            <div key={lottie.id} id={lottie.id} style={lottie.style}>
              <Lottie animationData={BannerBackground} loop={true} />
            </div>
          ))}
        </div>

        <div className="container d-flex flex-column "
          style={{ height: '80%', backdropFilter: 'blur(5px)' }} >

          <div className="mt-4 d-flex flex-direction-row justify-content-between align-items-center" >
            <img
              className="MainLogo"
              src={Logo} />
          </div>



          <div className="row  justify-content-center"
            style={{ height: '100%', marginTop: '5rem' }}>

            <h1 className="MainTitle">
              Play, watch and <br></br> entertain yourself !</h1>

            <p className="MainSubTitle">
              Apps Factory is an app store-portal, offering a selection of mobile<br></br>
              applications for <span style={{ color: 'var(--main--green)' }} >Android</span> and  <span style={{ color: 'var(--main--green)' }} >IOS</span> phones, with entertaining online<br></br>
              games, video streaming, and utility apps that suits all ages.</p>

            <div className="row d-flex justify-content-center gap-3 align-items-center" >
              <button
                style={{ width: '150px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="primary-button"
                onClick={() => window.location.href = '/#LoginSection'}>Login</button>

              <button
                style={{ width: '150px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="primary-button"
                onClick={() => window.location.href = 'https://publisherads.click/?SMCampaign=52da4e38-b005-4667-aba8-a1ebe5425b6c&ClickID=YOUR_CLICK_ID&Pub_ID=YOUR_PUB_ID&Aff_ID=YOUR_AFF_ID&extra=YOUR_Dynamic_Parameter_IF_Needed&extra1=YOUR_Dynamic_Parameter_IF_Needed&utm_source=affiliate&utm_medium=cpc&utm_campaign=%7bcampaignid%7d&utm_content=%7badgroupid%7d&utm_term=%7bkeyword%7d&country=all&themeid=3&portal=AppsFactory'}>
                Subscribe 
                <FaCreditCard size={20} style={{marginLeft:"5px"}}  />
                </button>
            </div>

            <div className="row d-flex justify-content-center">
              <img style={{ width: '100px' }} src={scrollImage} />
            </div>

          </div>


        </div>
      </section>


      <div className="content-container pt-1" >
        {/* Applications */}
        <section className="container" style={{ marginTop: '30px' }} >
          <br></br>
          <h3>Application</h3>

          <div className="d-flex align-items-center justify-content-between">
            <div>

              <button
                className={`secondary_button ${activeButton === 'iOS' ? 'active' : ''}`}
                onClick={ShowIOS}
              >
                iOS
              </button>
              <button
                className={`secondary_button ${activeButton === 'Android' ? 'active' : ''}`}
                onClick={ShowAndroid}
              >
                Android
              </button>

              <button
                className={`secondary_button ${activeButton === 'huawei' ? 'active' : ''}`}
                style={{ marginLeft: '0' }}
                onClick={ShowHuawei}
              >
                Huawei
              </button>
            </div>


            {/* Carousel navigation buttons */}
            <div >
              <div className="d-flex" style={{ textAlign: "center" }}>
                <button className="slider_buttons" onClick={() => previous(1)}>
                  <span class="bi bi-arrow-left"></span>
                </button>
                <button className="slider_buttons" onClick={() => next(1)}>
                  <span class="bi bi-arrow-right"></span>
                </button>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="slider-container" key={"applications"}>
              <Slider
                ref={sliderRefs[1]}
                {...settings}>

                {AppArray?.length > 0 ? (
                  AppArray.map((app, index) => (
                    <div className="Login_Card" key={app.id}>
                      <div
                        className="card_images"
                        style={{
                          backgroundImage: `url(${app.Screenshots[0] ? app.Screenshots[1] : DefaultBackground})`,
                        }}>
                        <img
                          className="card_icon m-2"
                          src={app.Logo ? app.Logo : AppFactoryIcon}
                          alt="App Icon"
                        />
                      </div>

                      <div className="card_desc">
                        <h5 className="line-clamp-1" >{app.AppName}</h5>
                        <p className="line-clamp-2">
                          {app.Description
                            ? app.Description.replace(/<p>/g, '').replace(/<\/p>/g, '')
                            : `Introducing ${app.AppName} Mobile application`}
                        </p>
                      </div>

                      <button className="btn btn-primary w-100 primary-button"
                        onClick={() => ViewAppDetail(app)} >View</button>
                    </div>
                  ))
                ) : (
                  <p>No applications available.</p>
                )}

              </Slider>
            </div>

          </div>

        </section>

        {/* Online Games */}
        <section className="container" style={{ marginTop: '30px' }}>
          <br />
          <h3>Online Games</h3>

          <div className="d-flex align-items-center justify-content-between">
            <div>
              {GamesArray.slice(0, 5).map((game, index) => {
                return (
                  <button
                    key={index}
                    className={`secondary_button ${activeGameButton === index ? 'active' : ''}`} // Conditionally add active class
                    style={{ marginLeft: '0' }}
                    onClick={() => ChangeOnlineGames(index)}
                  >
                    {game.name}
                  </button>
                );
              })}
            </div>

            {/* Carousel navigation buttons */}
            <div>
              <div className="d-flex" style={{ textAlign: 'center' }}>
                <button className="slider_buttons" onClick={() => previous(2)}>
                  <span className="bi bi-arrow-left"></span>
                </button>
                <button className="slider_buttons" onClick={() => next(2)}>
                  <span className="bi bi-arrow-right"></span>
                </button>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="slider-container"  key={'onlinegames'}>
              <Slider ref={sliderRefs[2]} {...settings}>
                {GamesArray?.length > 0 ? (
                  GamesArray[GameIndex].gameItems.map((category, index) => (
                    <div className="Login_Card" key={index}>
                      <div
                        className="card_images"
                        style={{ backgroundImage: `url(${category.image})` }}
                      ></div>

                      <div className="card_desc">
                        <h5 className="line-clamp-1">{category.title}</h5>
                      </div>

                      <button
                        onClick={() => (window.location.href = '/#LoginSection')}
                        className="btn btn-primary w-100 primary-button"
                      >
                        Play
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No applications available.</p>
                )}
              </Slider>
            </div>
          </div>
        </section>

        {/* Videos */}
        <section className="container" style={{ marginTop: '30px' }}>
          <br />
          <h3>Videos & Movies</h3>

          <div className="d-flex align-items-center justify-content-between">
            <div>
              {VideosArray.slice(0, 3).map((category, index) => (
                <button
                  key={index}
                  className={`secondary_button ${activeVideoButton === index ? 'active' : ''}`} // Conditionally add active class
                  style={{ marginLeft: '0' }}
                  onClick={() => ChangeVideo(index)}
                >
                  {category.name}
                </button>
              ))}
            </div>

            {/* Carousel navigation buttons */}
            <div>
              <div className="d-flex" style={{ textAlign: 'center' }}>
                <button className="slider_buttons" onClick={() => previous(3)}>
                  <span className="bi bi-arrow-left"></span>
                </button>
                <button className="slider_buttons" onClick={() => next(3)}>
                  <span className="bi bi-arrow-right"></span>
                </button>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="slider-container" key={"videos&movies"}>
              <Slider ref={sliderRefs[3]} {...settings}>
                {VideosArray?.length > 0 ? (
                  VideosArray[VideoIndex].videos.map((category, index) => (
                    <div
                      className="Login_Card"
                      style={{ cursor: 'pointer' }}
                      onClick={() => (window.location.href = '/#LoginSection')}
                      key={index}
                    >
                      <div
                        className="card_images"
                        style={{ backgroundImage: `url(${category.image})` }}
                      >
                        <img className="PlayButton" src={PlayButton} />
                      </div>

                      <div className="card_desc">
                        <h5 className="line-clamp-1">{category.title}</h5>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No applications available.</p>
                )}
              </Slider>
            </div>
          </div>
        </section>

        {/* Live Channels */}
        <section className="container" style={{ marginTop: '30px' }} >
          <br></br>
          <h3>Live Channels
            <img style={{ paddingLeft: '1rem' }} src={LiveLogo} />
          </h3>



          <div className="mt-4">
            <div className="slider-container" key={"livechannels"}>
              <Slider
                ref={sliderRefs[4]}
                {...settings}>

                {LiveChannels?.length > 0 ? (
                  LiveChannels.map((channel, index) => (

                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => window.location.href = '/#LoginSection'}
                      className="Login_Card" key={index}>
                      <div
                        className="card_images"
                        style={{ backgroundImage: `url(${channel.ChannelThumbnail})` }}>
                      </div>

                      <div className="card_desc">
                        <h5 className="line-clamp-1" >{channel.ChannelName}</h5>
                        <p className="line-clamp-1" >{channel.ChannelDescription}</p>
                      </div>

                    </div>
                  ))
                ) : (
                  <p>No applications available.</p>
                )}

              </Slider>
            </div>

          </div>

        </section>


        <Footer />
      </div>


    </div>
  )
}