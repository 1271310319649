
import axios from 'axios';

async function GetAndroidApps() {
    try {
        const response = await axios.post(
            "https://vsdllcifpl.execute-api.eu-central-1.amazonaws.com/live/appsfactory",
            { type: "3", limit: "1000" },
            { headers: { 'Content-Type': 'application/json' } }
        );

        const res = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
        const data = res.Result;

        const apkApps = [];
        const googleplay = [];
        const huawei = []; // New array for Huawei apps

        data.forEach(app => {
            if (app.StoreLink === null) {
                apkApps.push({ ...app, Screenshots: app.Screenshots || [] });
            } else if (app.StoreLink.includes("appgallery.huawei.com")) {
                huawei.push({ ...app, Screenshots: app.Screenshots || [] });
            } else {
                googleplay.push({ ...app, Screenshots: app.Screenshots || [] });
            }
        });

        console.log({ apkApps, googleplay, huawei });
        return { apkApps, googleplay, huawei }; // Return all three arrays
    } catch (error) {
        console.error("Error fetching Android apps:", error);
        return { apkApps: [], googleplay: [], huawei: [] }; // Return empty arrays in case of error
    }
}

async function GetIOSApps() {
    try {
        const response = await axios.post("https://vsdllcifpl.execute-api.eu-central-1.amazonaws.com/live/appsfactory", {
            type: '4',
            limit: '10000'
        }, {
            headers: { 'Content-Type': 'application/json' }
        });

        const res = JSON.parse(response.data);
        return res.Result || [];
    } catch (error) {
        console.error("Error fetching iOS apps:", error);
        return [];
    }
}

async function GetGames() {
    try {
        const response = await axios.post("https://vsdllcifpl.execute-api.eu-central-1.amazonaws.com/live/appsfactory", {
            type: '1',
            limit: '10000'
        }, {
            headers: { 'Content-Type': 'application/json' }
        });

        const res = JSON.parse(response.data);
        return res || [];
    } catch (error) {
        console.error("Error fetching games:", error);
        return [];
    }
}

async function GetVideos() {
    try {
        const response = await axios.post("https://vsdllcifpl.execute-api.eu-central-1.amazonaws.com/live/appsfactory", {
            type: '2',
            limit: '10000'
        }, {
            headers: { 'Content-Type': 'application/json' }
        });

        const res = JSON.parse(response.data);
        return res || [];
    } catch (error) {
        console.error("Error fetching videos:", error);
        return [];
    }
}

async function GetLiveChannels() {
    try {
        const response = await axios.get("https://apiv2.mobileartsme.com/av_tvchannels", {
            headers: { 'Content-Type': 'application/json' }
        });

        return response.data.Channels || [];
    } catch (error) {
        console.error("Error fetching live channels:", error);
        return [];
    }
}

export async function GetAllData() {
    // Check if all data exists in session storage
    const storedData = sessionStorage.getItem('AllData');
    if (storedData) {
        return JSON.parse(storedData);
    }
    const AndroidApps = await GetAndroidApps();
    const IOSApps = await GetIOSApps();
    const Games = await GetGames();
    const Videos = await GetVideos();
    const LiveChannels = await GetLiveChannels();

    const allData = { AndroidApps, IOSApps, Games, Videos, LiveChannels };
    sessionStorage.setItem('AllData', JSON.stringify(allData));

    return allData;
}

export function Logout() {
    localStorage.removeItem("AF_Login_Data")
    window.location.reload()
}

async function AutoLogin() {
    const AF_Login_Data = localStorage.getItem("AF_Login_Data");
    const urlParams = window.location.pathname;

    if (urlParams === '/' && AF_Login_Data) {
        window.location.href = '/application';
        
    }
    else if (urlParams !== "/" && !AF_Login_Data && urlParams !== "/AppDetails" && urlParams !== "/ContactUs") {
        window.location.href = '/';
    }

}




AutoLogin()

export { GetAndroidApps, GetIOSApps, GetGames, GetVideos, GetLiveChannels };
