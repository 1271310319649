import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AppDetails from './pages/AppDetails';
import TestPage from './pages/Test';
import './App.css'
import Applications from './pages/Applications';
import Games from './pages/Games'
import Videos from './pages/Videos'
import Downloadable from './pages/Downloadable';
import Streaming from './pages/Streaming';
import AppDetails_Second from './pages/AppDetails_Second';
import ContactUs from './pages/Contactus';

function App() {
  return (
    <Router>
            <Routes>
            <Route path="/" Component={Login} />
            <Route path="/Dashboard" Component={Dashboard} />
            <Route path="/AppDetails" Component={AppDetails} />
            <Route path="/Test" Component={TestPage} />
            <Route path="/ContactUs" Component={ContactUs} />
            <Route path="/AppDetails_second" Component={AppDetails_Second} />
              <Route path="/application"  Component={Applications}/>
              <Route path="/Games" Component={Games} />
              <Route path="/Downloadable" Component={Downloadable} />
              <Route path="/videos" Component={Videos} />
              <Route path="/Streaming" Component={Streaming} />
              {/* Add more routes as needed */}
            </Routes>
    
   
    </Router>
  );
}

export default App;
