import React, { useState } from 'react';
import '../styles/Dashboard.css'; // Add any custom styles if needed
import Header from '../components/Header';
import SidebarAnt from '../components/SidebarAnt';
const Dashboard = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (

        <section className='d-flex ' style={{ height: '100vh'}}>

            <SidebarAnt/>
            <div className='main-content' >
                <div className='Dashboard_Header' >
           

                        <Header/>
                   
                </div>

                <div className='Dashboard_Body' >
                    
                </div>
            </div>

        </section>
    );
};

export default Dashboard;
