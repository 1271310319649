import React, { useState } from "react";

export default function Header() {
  const AF_Login_Data = JSON.parse(localStorage.getItem('AF_Login_Data'));

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase(); // Get the search query and convert to lowercase
    const items = document.querySelectorAll("[data-index]"); // Select all elements with the "data-index" attribute

    items.forEach((item) => {
      const indexValue = item.getAttribute("data-index").toLowerCase(); // Get the value of "data-index" attribute
      if (indexValue.includes(query)) {
        item.style.display = ""; // Show matching items
      } else {
        item.style.display = "none"; // Hide non-matching items
      }
    });
  };

  return (
    <div
      className="pt-4 d-flex flex-column flex-md-row justify-content-between align-items-center"
      style={{ background: 'white', height: 'auto' }}
    >
      <div className="row w-100">
        <div className="col-12 col-md-4 mb-2 mb-md-0">
          <h4 className="m-0 ">
            Welcome, <span style={{ fontWeight: 'normal' }}>{AF_Login_Data?.username || "Guest"}</span>
          </h4>
        </div>

        <div className="col-12 col-md-8">
          <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
            {/* Filter Dropdown */}
            <div className="d-flex align-items-center w-100 flex-fill">
              <p
                className="mb-0 mr-2"
                style={{
                  whiteSpace: 'nowrap', // Prevent wrapping of text
                  flexShrink: 0,        // Prevent shrinking
                }}
              >
                Filter By
              </p>
              <select
                className="form-control flex-fill"
                style={{
                  background: '#f8f9fa',
                  border: 'none',
                  padding: '6px 12px',
                  borderRadius: '4px',
                }}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  if (selectedValue) {
                    window.location.href = selectedValue;
                  }
                }}
              >
                <option value="">All</option>
                <option value="/Games?Category=0">Games</option>
                <option value="/Videos?Category=0">Videos</option>
                {/* Add more options as needed */}
              </select>
            </div>

            {/* Search Input */}
            <div className="d-flex align-items-center position-relative w-100 flex-fill">
              <input
                className="form-control flex-fill"
                style={{
                  background: '#f8f9fa',
                  border: 'none',
                  padding: '6px 12px',
                  paddingRight: '30px', // Space for search icon
                  borderRadius: '4px',
                }}
                placeholder="Type Keywords to Filter"
                onChange={handleSearch} // Add onChange handler
              />
              <span
                style={{
                  position: 'absolute',
                  right: '10px',
                  color: '#6c757d',
                }}
              >
                <i className="fas fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
