import React, { useEffect, useState } from "react";
import SidebarAnt from '../components/SidebarAnt';
import { GetAllData } from "../functions/apis";
import "../styles/Applications.css";
import Header from '../components/Header';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Downloadable() {
    const [Apks, setApks] = useState([]);

    useEffect(() => {
        const allData = JSON.parse(sessionStorage.getItem("AllData"));


        if (allData) {
            setApks(allData.AndroidApps.apkApps || []);
        }

    }, []);



    return (
        <section className='d-flex' style={{ height: '100vh' }}>
            <SidebarAnt />
            <div className='main-content'>
                <Header />
                <div className='Dashboard_Body'>
                    {Apks.map((apk, index) => {
                        return (
                            <div className="App_Card" 
                            data-index={apk.AppName}
                            key={index}>
                                <LazyLoadImage
                                    className="App_Icon"
                                    src={apk.Logo}
                                    alt={apk.AppName}
                                    effect="blur" // Adds a blur effect while loading
                                />
                                <p className="App_Name">{apk.AppName}</p>
                                <button
                                    onClick={() => {
                                        window.location.href = `${apk.StoreDeeplink}`;
                                    }} style={{ background: '#12924B', color: 'white' }}
                                    className="Play_Button_Games">
                                    Download
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}
