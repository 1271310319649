import React, { useEffect, useState, useRef } from "react";
import Logo from '../assets/icons/sidebar/appsfactory-fulllogo.svg'
import Playstore from '../assets/icons/cards/android.svg'
import Appstore from '../assets/icons/cards/appstore.svg'
import { Button } from "bootstrap";
import Slider from "react-slick";
import Huawei from '../assets/icons/cards/AppGallery.png'

import SidebarAnt from '../components/SidebarAnt';

import Header from '../components/Header';



export default function AppDetails_Second() {

    const [App, setApp] = useState({});

    useEffect(() => {
        const queryString = new URLSearchParams(window.location.search);
        const encodedData = queryString.get('data'); // Get the encoded data using 'data' as the key
        if (encodedData) {
            try {
                // Decode the Base64 string and parse the JSON
                const decodedString = atob(encodedData);
                const parsedData = JSON.parse(decodedString);
                setApp(parsedData);
                console.log(parsedData)
            } catch (error) {
                console.error("Failed to parse JSON:", error);
            }
        }
    }, []);

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };



    return (

        <section className='d-flex ' style={{ height: '100vh' }}>
            <SidebarAnt />
            <div className='main-content' >

                <div className="row"
                    style={{ height: '100vh', margin: '0' }} >

                    <div className="col-lg-6 p-0" style={{ backgroundColor: 'transparent' }} >

                        <div style={{ display: 'flex', marginTop: '4rem', flexDirection: 'column', gap: '2rem' }} >
                            <a
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent the default anchor behavior
                                    window.history.back(); // Go to the previous page
                                }}
                                style={{ color: 'black', cursor: 'pointer', textDecoration: 'none' }}
                            >
                                🡨 Back
                            </a>


                            <div style={{ display: 'flex', height: '100px', gap: '15px', alignItems: 'center' }}  >
                                <img
                                    style={{ borderRadius: '10px', height: '5rem' }}
                                    src={App.Logo} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} >
                                    <h1 className="line-clamp-1" style={{ color: 'black' }} >
                                        {App.AppName}
                                    </h1>

                                    <div>
                                        <button
                                            className="os_button d-flex"
                                            onClick={() => window.location.href = App.StoreDeeplink || '#'}
                                        >
                                            <img
                                                style={{ height: '100%' }}
                                                src={
                                                    App.Platform === "IOS"
                                                        ? Appstore
                                                        : App.StoreDeeplink?.includes("appgallery")
                                                            ? Huawei
                                                            : Playstore
                                                }
                                                alt="App Store Icon"
                                            />
                                            <div className="d-flex flex-column align-items-start">
                                                <span style={{ fontSize: '11px', color: 'white' }}>Available on</span>
                                                <span style={{ color: 'white', fontWeight: '700' }}>
                                                    {App.Platform === "IOS"
                                                        ? "App Store"
                                                        : App.StoreDeeplink?.includes("appgallery")
                                                            ? "App Gallery"
                                                            : "Google Play"}
                                                </span>
                                            </div>
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <div style={{ borderTop: '1px solid #ffffff59' }} ></div>

                            <div style={{maxWidth:'80%'}} >
                                <h2 style={{ color: 'black' }} >Description</h2>
                                <p
                                    style={{ fontSize: '0.7rem' }}
                                >{App.Description ? App.Description.replace(/<p>/g, '').replace(/<\/p>/g, '') :
                                    'No Description for this app'}</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-6 " style={{ backgroundColor: '#f8f9fa' }} >
                        <div style={{ display: 'flex', flexDirection: 'column' }} >

                            <div className="mt-5" >
                                <button
                                    style={{
                                        background: '#585858',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '5px'
                                    }}
                                    onClick={previous}>

                                    <span class="bi bi-arrow-left"></span>
                                </button>

                                <button
                                    style={{
                                        background: '#2FDA7B',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '5px'
                                    }}
                                    onClick={next}>
                                    <span class="bi bi-arrow-right"></span>
                                </button>
                            </div>

                            <div className="mt-4">
                                <div className="slider-container">
                                    <Slider
                                        ref={slider => {
                                            sliderRef = slider;
                                        }}
                                        {...settings}>

                                        {App.Screenshots?.length > 0 ? (
                                            App.Screenshots.map((screenshot, index) => (

                                                <img className="Screenshots_slider"

                                                    src={screenshot} />

                                            ))
                                        ) : (
                                            <p>No applications available.</p>
                                        )}

                                    </Slider>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>



            </div>

        </section>


    )
}