import React, { useEffect, useState } from "react";
import SidebarAnt from '../components/SidebarAnt';
import { GetAllData, GetLiveChannels } from "../functions/apis";
import "../styles/Applications.css";
import Header from '../components/Header';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Games() {
    const [Games, setGames] = useState([]);
    const [GameLink, setGameLink] = useState('');

    useEffect(() => {
        const allData = JSON.parse(sessionStorage.getItem("AllData"));
        const urlParams = new URLSearchParams(window.location.search);
        const Category = urlParams.get("Category");

        if (allData) {
            setGames(allData.Games[Category].gameItems || []);
        }
        console.log("Games page");
    }, [window.location.search]);

    const playGame = (link) => {
        setGameLink(link);
    };

    const closeModal = () => {
        setGameLink('');
    };

    return (
        <section className='d-flex' style={{ height: '100vh' }}>
            <SidebarAnt />
            <div className='main-content'>
                <Header />
                <div className='Dashboard_Body'>
                    {Games.map((game, index) => {
                        return (
                            <div className="App_Card" key={index} data-index={game.title}>
                                <LazyLoadImage
                                    className="App_Icon"
                                    src={game.image}
                                    alt={game.title}
                                    effect="blur" // Adds a blur effect while loading
                                />
                                <p className="App_Name">{game.title}</p>
                                <button
                                    onClick={() => playGame(game.content)}
                                    className="Play_Button_Games">
                                    Play
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Modal */}
            {GameLink && (
                <div className="Modal_Backdrop">
                    <div className="Modal_Content">
                        <button className="Close_Button" onClick={closeModal}>
                            Close
                        </button>
                        <iframe
                            src={GameLink}
                            className="Game_Frame"
                            title="Game"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )}
        </section>
    );
}
