import React, { useEffect, useState } from "react";
import SidebarAnt from '../components/SidebarAnt';
import { GetAllData, GetLiveChannels } from "../functions/apis";
import Android from '../assets/icons/cards/android.svg'
import Appstore from '../assets/icons/cards/apple.svg'

import Appstore_White from '../assets/icons/cards/appstore.svg'
import Playstore from '../assets/icons/cards/googleplay.svg'

import "../styles/Applications.css"
import Header from '../components/Header';

export default function TestPg() {

    const [apps, setApps] = useState([]);

    useEffect(() => {
        const allData = JSON.parse(sessionStorage.getItem("AllData"));
        const urlParams = new URLSearchParams(window.location.search);
        const platform = urlParams.get("platform");

        if (allData) {
            if (platform === "android" && allData.AndroidApps) {
                setApps(allData.AndroidApps.googleplay || []);
            } else if (platform === "ios" && allData.IOSApps) {
                setApps(allData.IOSApps || []);
            } else {
                // If platform is not specified, mix random apps from Android and iOS
                const mixedApps = getRandomMixedApps(allData.AndroidApps?.googleplay || [], allData.IOSApps || [], 20); // Number of apps to show
                setApps(mixedApps);
            }
        } else {
            console.warn("No data found in sessionStorage for 'AllData'");
        }
    }, [window.location.search]);



    const getRandomMixedApps = (androidApps, iosApps, count) => {
        const combinedApps = [...androidApps, ...iosApps];
        const shuffled = combinedApps.sort(() => 0.5 - Math.random()); // Shuffle array
        return shuffled.slice(0, count); // Return 'count' number of random apps
    };



    return (
        <section className='d-flex ' style={{ height: '100vh' }}>
            <SidebarAnt />
            <div className='main-content' >
                <Header />
                <div className='Dashboard_Body'>

                    {apps.map((app, index) => {
                        return (
                            <div className="App_Card" >
                                {
                                    app.Platform === "IOS" ?
                                        <img className="Platform_Icon" src={Appstore} /> :
                                        <img className="Platform_Icon" src={Android} />
                                }


                                <img className="App_Icon" src={app.Logo} />

                                <p className="App_Name" >{app.AppName}</p>

                                <button className="os_button_2 d-flex "
                                    style={{
                                        background: app.Platform === "IOS" ? '#757575bf' : '#26C46D'
                                    }}
                                >
                                    <img style={{ height: '100%' }}
                                        src={app.Platform == "IOS" ? Appstore_White : Playstore} />
                                    <div className="d-flex flex-column align-items-start" >
                                        <span style={{ fontSize: '10px', color: 'white' }} >Available on</span>
                                        <span style={{ fontSize: '13px', color: 'white', fontWeight: '700' }} >App Store</span>
                                    </div>
                                </button>


                            </div>
                        )
                    })}

                </div>

            </div>

        </section>
    )
}